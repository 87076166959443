import { useSelect } from '@pankod/refine-core';

import { useEffect, useMemo } from 'react';

import { capitalize } from 'utils/generalUtils';

interface ISelectResourceProps {
  selectProps: any;
  resource: string;
  defaultValue?: any;
  optionLabel?: string;
  labelModifier?: (label: string) => string;
  optionsEffect?: () => void;
}
const SelectResource: React.FC<ISelectResourceProps> = ({
  selectProps,
  resource,
  defaultValue,
  optionLabel = 'id',
  labelModifier = (v) => v,
  optionsEffect = () => {},
}) => {
  const { options } = useSelect({
    resource: resource,
    defaultValue: defaultValue,
    optionLabel: optionLabel,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(optionsEffect, [options]);
  const singular = useMemo(() => {
    return resource.slice(0, resource.length - 1);
  }, [resource]);
  return (
    <>
      <label htmlFor={singular} className="my-label">
        {capitalize(singular)}
      </label>
      <select defaultValue={''} className="my-input" {...selectProps}>
        <option value={''} disabled>
          Please select
        </option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {labelModifier(option.label)}
          </option>
        ))}
      </select>
    </>
  );
};
export default SelectResource;
