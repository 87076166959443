import { useOne } from '@pankod/refine-core';

interface IShowResourceProps {
  resource: string;
  id: string | number;
  enabled: boolean;
  extractProp: (data: any) => string;
}
const ShowResource: React.FC<IShowResourceProps> = ({
  resource,
  id,
  enabled,
  extractProp,
}) => {
  const { data, isLoading } = useOne<any>({
    resource: resource,
    id: id,
    queryOptions: {
      enabled: enabled,
    },
  });
  const value = isLoading
    ? 'Loading...'
    : data
    ? extractProp(data.data)
    : 'Failed to load';
  return <input value={value} disabled className="my-input" />;
};
export default ShowResource;
