import GenericCreateOrEdit from 'components/GenericCreateOrEdit';
import GenericList from 'components/GenericList';
import GenericShow from 'components/GenericShow';
import ResourceCell from 'components/ResourceCell';
import endpoints from 'constants/endpoints';
import foreignKeys from 'constants/foreignKeys';

const webinarSignupsResource = {
  //name: endpoints.webinarSignups,
  name: "webinarSignups",

  show: () => (
    <GenericShow
      values={[
        {
          type: 'text',
          accessor: 'id',
          label: 'Id',
        },
        {
          type: 'text',
          accessor: 'name',
          label: 'Name',
        },
        {
          type: 'text',
          accessor: 'email',
          label: 'Email',
        },
        {
          type: 'text',
          accessor: 'phone',
          label: 'Phone',
        },
        {
          type: 'resource',
          resource: endpoints.webinars,
          label: 'Webinar',
          accessor: foreignKeys.webinarId,
          extractProp: (data) => data.name,
        },
        {
          type: 'resource',
          resource: endpoints.webinarSchedules,
          label: 'Webinar Schedule',
          accessor: foreignKeys.webinarScheduleId,
          extractProp: (data) => new Date(data.startTime).toLocaleString(),
        },
      ]}
    />
  ),
  list: () => {
    return (
      <GenericList
        resource="webinarSignups"
        columns={[
          {
            id: 'id',
            Header: 'ID',
            accessor: 'id',
          },
          {
            id: 'uuid',
            Header: 'UUID',
            accessor: 'uuid',
          },
          {
            id: 'name',
            Header: 'name',
            accessor: 'name',
            filter: 'contains',
          },
          {
            id: 'email',
            Header: 'email',
            accessor: 'email',
            filter: 'contains',
          },
          {
            id: 'phone',
            Header: 'phone',
            accessor: 'phone',
          },

          {
            id: foreignKeys.webinarId,
            Header: 'webinar',
            accessor: foreignKeys.webinarId,
            Cell: ({ cell }) => (
              <ResourceCell cell={cell} resource="webinars" label="name" />
            ),
          },
          {
            id: foreignKeys.webinarScheduleId,
            Header: 'webinar schedule',
            accessor: foreignKeys.webinarScheduleId,
            Cell: ({ cell }) => {
              return (
                <ResourceCell
                  cell={cell}
                  resource="webinarSchedules"
                  label="startTime"
                  labelModifier={(startTime) =>
                    new Date(startTime).toLocaleString()
                  }
                />
              );
            },
          },
        ]}
        filtersData={[
          {
            id: 'id',
            label: 'Id',
            type: 'number',
            inputWidth: 50,
          },
          {
            id: 'uuid',
            label: 'UUID',
            type: 'text',
            inputWidth: 100,
          },
          {
            id: 'name',
            label: 'Name',
            type: 'text',
          },
          {
            id: 'email',
            label: 'Email',
            type: 'text',
          },
          {
            id: 'webinarId',
            label: 'WebinarId',
            type: 'number',
            inputWidth: 100,
          },
          {
            id: 'webinarScheduleId',
            label: 'WebinarScheduleId',
            type: 'number',
            inputWidth: 100,
          },
        ]}
      />
    );
  },

  create: () => (
    <GenericCreateOrEdit
      fields={[
        {
          type: 'text',
          name: 'name',
        },
        {
          type: 'text',
          name: 'email',
        },
        {
          type: 'text',
          name: 'phone',
          label: 'Whatsapp No.',
        },
        {
          type: 'select',
          name: foreignKeys.webinarId,
          resource: 'webinars',
          optionLabel: 'name',
        },
        {
          type: 'select',
          name: foreignKeys.webinarScheduleId,
          resource: 'webinarSchedules',
          optionLabel: 'startTime',
          labelModifier: (startTime) => new Date(startTime).toLocaleString(),
        },
      ]}
    />
  ),
  edit: () => (
    <GenericCreateOrEdit
      fields={[
        {
          type: 'text',
          name: 'name',
        },
        {
          type: 'text',
          name: 'email',
        },
        {
          type: 'text',
          name: 'phone',
        },
        {
          type: 'select',
          name: foreignKeys.webinarId,
          resource: 'webinars',
          optionLabel: 'name',
        },
        {
          type: 'select',
          name: foreignKeys.webinarScheduleId,
          resource: 'webinarSchedules',
          optionLabel: 'startTime',
          labelModifier: (startTime) => new Date(startTime).toLocaleString(),
        },
      ]}
    />
  ),
};
export default webinarSignupsResource;
