import { useSelect } from '@pankod/refine-core';

import axios from 'axios';
import axiosInstance from 'axiosInstance';
import useRefresh from 'hooks/useRefresh';
import {
  Webinar,
  WebinarCategory,
  webinarScheduleType,
  WebinarScheduleType,
} from 'interfaces/apiTypes';
import QueryString from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import CategoryPill from 'components/CategoryPill';
import CutVideo from 'components/CutVideo';
import ExpandableItem from 'components/ExpandableItem';
import FileUpload from 'components/FileUpload';
import FilledButton from 'components/FilledButton';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import LivePeopleGraph from 'components/LivePeopleGraph/LivePeopleGraph';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import ScheduleWebinarAtInterval from 'components/ScheduleWebinarAtInterval';
import ScheduleWebinarAtRelativeTime from 'components/ScheduleWebinarAtRelativeTime';
import ScheduleWebinarToParticularPoint from 'components/ScheduleWebinarToParticularPoint';
import SetupImmediateSlot from 'components/SetupImmediateSlot';
import SetupPermanentSlots from 'components/SetupPermanentSlots';
import SetupRecurringSchedule from 'components/SetupRecurringSchedule';
import WebinarExpandableItem from 'components/WebinarExpandableItem';
import WebinarFileUpload from 'components/WebinarFileUpload';
import endpoints from 'constants/endpoints';
import foreignKeys from 'constants/foreignKeys';
import { getMessages } from 'utils/firebaseUtils';
import { capitalize } from 'utils/generalUtils';
import webinarUtils from 'utils/webinarUtils';

export const WebinarShow: React.FC = () => {
  const url = window.location.href.split('/');
  const webinarId = url?.[url.length - 1];
  const [firebaseMesages, setFirebaseMessages] = useState<any>([]);
  const webinarQuery = useQuery(
    ['webinars', webinarId],
    () =>
      axiosInstance.get<Webinar & { webinarCategories: WebinarCategory[] }>(
        `/v1/webinars/${webinarId}?_expand=webinarCategories&_expand=webinarTopics`
      ),
    {
      enabled: !!webinarId,
    }
  );

  const webinar = webinarQuery?.data?.data;
  /* The below code is using the useEffect hook to set firebase messages. It is setting up a side effect
 that will be triggered whenever the value of the webinarId variable changes. */
  useEffect(() => {
    if (webinarId) {
      getMessages(setFirebaseMessages, +webinarId);
    }
  }, [webinarId]);
  const resourcesQuery = useQuery(
    ['webinars', webinar?.id, 'resources'],
    () => axiosInstance.get(`/v1/webinars/${webinar?.id}/resources`),
    {
      enabled: !!webinar?.id,
    }
  );

  // const webinarThemeColor = webinarQuery?.data?.data;

  // const webinarThemeColorQuery = useQuery(
  //   ['webinars', webinar?.id],
  //   () => axiosInstance.get(`/webinars/${webinar?.id}`),
  //   {
  //     enabled: !!webinar?.id,
  //   }
  // );

  const WebinarThemeColorId = webinar?.paletteId;

  const ThemeColor = webinarUtils.getColorPalette(webinar?.paletteId)[0]
    ?.cardColor;

  const existingCategories = useMemo(() => {
    return webinar?.webinarCategories.map((item) => item.name);
  }, [webinar?.webinarCategories]);

  const existingTopics = useMemo(() => {
    //@ts-ignore
    return webinar?.webinarTopics?.map((item) => item.name);
    //@ts-ignore
  }, [webinar?.webinarTopics]);

  const rawResourceQuery = useQuery(
    ['webinars', webinar?.id, 'rawResource'],
    () => axiosInstance.get(`/v1/webinars/${webinar?.id}/rawResource`),
    {
      enabled: !!webinar?.id,
    }
  );

  if (!webinar) {
    return (
      <p data-test="non-existent-webinar-message">
        No webinar with id: {webinarId}
      </p>
    );
  }

  // const setWebinarThemeColor = (paletteId) => {
  //   let colorpaletteId = Number(paletteId)
  //   setSelectedColorPaletteId(Number(paletteId))
  //   setSelectedColorPaletteColor(webinarUtils.getColorPalette(colorpaletteId)[0].cardColor);
  // }

  return (
    <OuterContainer>
      <div className="flex justify-end">
        <Link to={`/webinars/edit/${webinar.id}`}>
          <IconButton onClick={() => { }}>{EditIcon}</IconButton>
        </Link>
      </div>
      <ItemsContainer>
        <div>
          <label className="my-label" htmlFor="id">
            Id
          </label>
          <input id="id" value={webinar.id} disabled className="my-input" />
        </div>
        <div>
          <label className="my-label" htmlFor="name">
            Name
          </label>
          <input id="name" value={webinar.name} disabled className="my-input" />
        </div>

        <div>
          <label className="my-label" htmlFor="name">
            Preheader
          </label>
          <input
            id="name"
            value={webinar?.preHeader}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label htmlFor="categoryInput" className="my-label">
            Webinar Category
          </label>
          <div className="flex gap-x-2">
            {existingCategories?.map((item) => {
              return (
                <CategoryPill key={item}>
                  <p>{item}</p>
                </CategoryPill>
              );
            })}
          </div>
        </div>

        <div>
          <label htmlFor="topicInput" className="my-label">
            Webinar Topics
          </label>
          <div className="flex gap-x-2">
            {existingTopics?.map((item) => {
              return (
                <CategoryPill key={item}>
                  <p>{item}</p>
                </CategoryPill>
              );
            })}
          </div>
        </div>

        <div>
          <label htmlFor="categoryInput" className="my-label">
            Webinar Theme Color
          </label>
          <div
            className={`w-[50px] h-[10px] ${ThemeColor} rounded-[2px]`}
          ></div>
          <div className="flex gap-x-2">{WebinarThemeColorId}</div>
        </div>

        <div>
          <label htmlFor="categoryInput" className="my-label">
            Webinar Theme Shape
          </label>
          <a
            className="text-[blue] cursor-pointer"
            href={webinar.webinarThemeShape}
          >
            {webinar.webinarThemeShape}
          </a>
          <img
            src={webinar.webinarThemeShape}
            alt="webinar Themeshape"
            className="pt-[10px]"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="name">
            Webinar Type
          </label>
          <input
            id="name"
            value={webinar.webinarType}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="fbqPixelId">
            Facebook Tracking PixelId
          </label>
          <input
            id="fbqPixelId"
            value={webinar.fbqPixelId}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="programId">
            ProgramId
          </label>
          <input
            id="programId"
            value={webinar.programId}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="duration">
            Duration
          </label>
          <input
            id="duration"
            value={webinar.duration}
            disabled
            className="my-input"
          />
        </div>
        <div>
          <VideoAndThumbnail webinar={webinar} />
        </div>
      </ItemsContainer>
      <div className="mt-8">
        <h2 className="font-bold text-2xl mb-4">Details about this Webinar</h2>
        <div className="flex space-x-2 flex-wrap">
          <Link
            to={`/${endpoints.webinarSchedules}?${foreignKeys.webinarId}=${webinar.id}`}
          >
            <FilledButton>View Schedules</FilledButton>
          </Link>
          <FilledButton>View Signups</FilledButton>
          <FilledButton>View Messages</FilledButton>
          <FilledButton>View Polls</FilledButton>
          <FilledButton>View Ctas</FilledButton>
          <FilledButton>View Events</FilledButton>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-bold text-2xl mb-4">Resources for this Webinar</h2>
        {/* <pre>{JSON.stringify(resourcesQuery.data?.data, null, 4)}</pre> */}
        <div>
          {resourcesQuery.data?.data &&
            (() => {
              const {
                messages,
                ctas,
                polls,
                events,
                survey,
                communicationData,
                webinarMetadata,
              } = resourcesQuery.data?.data;
              const {
                messages: rawMessages,
                ctas: rawCtas,
                polls: rawPolls,
                events: rawEvents,
                survey: rawSurvey,
                communicationData: rawCommunicationData,
                webinarMetadata: rawWebinarMetadata,
              } = rawResourceQuery.data?.data || {};
              return (
                <div className="space-y-6">
                  <WebinarExpandableItem
                    itemsName="Messages"
                    dataKey="messages"
                    data={firebaseMesages}
                    rawData={rawMessages}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Ctas"
                    dataKey="ctas"
                    data={ctas}
                    rawData={rawCtas}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Polls"
                    dataKey="polls"
                    data={polls}
                    rawData={rawPolls}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Events"
                    dataKey="events"
                    data={events}
                    rawData={rawEvents}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Survey"
                    dataKey="survey"
                    data={survey?.json}
                    rawData={rawSurvey}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Communication Data"
                    dataKey="communicationData"
                    data={communicationData?.json}
                    rawData={rawCommunicationData}
                    webinar={webinar}
                  />
                  <WebinarExpandableItem
                    itemsName="Metadata"
                    dataKey="webinarMetadata"
                    data={webinarMetadata?.json}
                    rawData={rawWebinarMetadata}
                    webinar={webinar}
                  />
                </div>
              );
            })()}
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-bold text-2xl mb-4">
          Schedule this Webinar ({webinar?.name})
        </h2>
        <div className="mb-5 space-y-4">
          {Object.keys(webinarScheduleType).map((type) => {
            return (
              <DeleteParticularTypeSchedule
                key={type}
                type={type as WebinarScheduleType}
                webinar={webinar}
              />
            );
          })}
        </div>
        <div>
          <ItemsContainer>
            <div>
              <SetupImmediateSlot webinar={webinar} />
            </div>
            <p className="text-2xl font-bold">or</p>
            <div>
              <SetupPermanentSlots webinar={webinar} />
            </div>
            <p className="text-2xl font-bold">or</p>

            <div>
              <Link to={`/webinarSchedules/create`}>
                <FilledButton>
                  Click to Schedule At Particular Time
                </FilledButton>
              </Link>
            </div>
            <p className="text-2xl font-bold">or</p>
            <ScheduleWebinarAtInterval webinar={webinar} />

            <p className="text-2xl font-bold">or</p>
            <ScheduleWebinarToParticularPoint
              webinarDuration={webinar.duration}
              webinarId={webinar.id}
            />
            <p className="text-2xl font-bold">or</p>
            <SetupRecurringSchedule webinar={webinar} />
            <p className="text-2xl font-bold">or</p>
            <ScheduleWebinarAtRelativeTime webinar={webinar} />
          </ItemsContainer>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-bold text-2xl mb-4">Upload Files</h2>
        {resourcesQuery.data?.data && (
          <div>
            {(() => {
              const { messages } = resourcesQuery.data?.data;
              return (
                <ItemsContainer>
                  <WebinarFileUpload
                    webinar={webinar}
                    file="messages"
                    numPreviousResources={messages.length}
                  />
                </ItemsContainer>
              );
            })()}
          </div>
        )}
      </div>
      <div>
        <EnrollBuldUsers />
      </div>
      <div className="my-8">
        <h2 className="font-bold text-2xl mb-4 text-center">
          Setup Live People Graph{' '}
        </h2>
        <LivePeopleGraph webinar={webinar} />
      </div>
      <div className="my-8">
        <h2 className="font-bold text-2xl mb-4 text-center">Hosts</h2>
        <AddHosts webinarId={webinar.id}></AddHosts>
      </div>
    </OuterContainer>
  );
};

interface IVideoAndThumbnail {
  webinar: Webinar;
}
const VideoAndThumbnail: React.FC<IVideoAndThumbnail> = ({ webinar }) => {
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const queryClient = useQueryClient();
  useEffect(() => {
    (async () => {
      if (webinar.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${webinar.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [webinar.videoUuid]);
  const updateWebinarMutation = useMutation(
    ({ videoDuration }: { videoDuration: number }) => {
      return axios.patch(`${process.env.REACT_APP_SERVER}/v1/webinars/${webinar.id}`, {
        videoDuration,
      },
        { withCredentials: true }
      );
    },
    {
      onSuccess: (response) => {
        // console.log(response.data);
        queryClient.invalidateQueries();
      },
    }
  );
  useEffect(() => {
    // this is useful for the scenario if uploadedVideo duration was null
    // eg api sets the duration through queue which takes some time
    // so we update the duration when user visits the show page
    if (uploadedVideo) {
      if (
        webinar.videoDuration !== uploadedVideo.video.durationInMilliseconds
      ) {
        // updated webinar videoDuration
        updateWebinarMutation.mutate({
          videoDuration: Number(uploadedVideo.video.durationInMilliseconds),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedVideo, webinar.videoDuration]);
  const playerRef = useRef<HTMLVideoElement>(null);
  if (!uploadedVideo) return <></>;
  return (
    <ItemsContainer>
      <div>
        <label htmlFor="s3UrlBucket" className="my-label">
          Video Bucket
        </label>
        <input
          type="text"
          id="s3UrlBucket"
          className="my-input"
          disabled
          value={uploadedVideo?.video.s3UrlBucket}
        />
      </div>
      <div>
        <label htmlFor="s3UrlPrefix" className="my-label">
          Video Prefix
        </label>
        <input
          type="text"
          id="s3UrlPrefix"
          className="my-input"
          disabled
          value={uploadedVideo?.video.s3UrlPrefix}
        />
      </div>
      <div>
        <label className="my-label">Video Url</label>
        <input
          className="my-input"
          disabled
          value={uploadedVideo?.streams?.preferred?.hls?.url}
        />
      </div>
      <div>
        <ExpandableItem itemsName="uploadedVideo" data={uploadedVideo} />
      </div>
      <div>
        <ReactHlsPlayer
          playerRef={playerRef}
          src={uploadedVideo?.streams?.preferred?.hls?.url}
          controls
        />
      </div>
      <div>
        <CutVideo webinar={webinar} />
      </div>

      <div>
        <label htmlFor="thumbnailUrlBucket" className="my-label">
          Thumbnail Bucket
        </label>
        <input
          type="text"
          id="thumbnailUrlBucket"
          className="my-input"
          disabled
          value={uploadedVideo?.video.thumbnailUrlBucket}
        />
      </div>
      <div>
        <label htmlFor="thumbnailUrlPrefix" className="my-label">
          Thumbnail Prefix
        </label>
        <input
          type="text"
          id="thumbnailUrlPrefix"
          className="my-input"
          disabled
          value={uploadedVideo?.video.thumbnailUrlPrefix}
        />
      </div>
      <div>
        <label className="my-label">Thumbnail Url</label>
        <input
          className="my-input"
          disabled
          value={uploadedVideo?.streams?.thumbnails?.png}
        />
      </div>
      <div>
        <img
          src={uploadedVideo.streams.thumbnails.png}
          alt=""
          style={{
            maxHeight: '50vh',
            maxWidth: '50vw',
          }}
        />
      </div>
    </ItemsContainer>
  );
};

interface IAddHosts {
  webinarId: number;
}
const AddHosts: React.FC<IAddHosts> = ({ webinarId }) => {
  const hostsQuery = useQuery(['webinars', webinarId, 'hosts'], () => {
    return axiosInstance.get(`/v1/webinars/${webinarId}/hosts`);
  });
  const hosts = useMemo(() => hostsQuery.data?.data, [hostsQuery.data?.data]);
  return (
    <div>
      {!!hosts?.length && (
        <div>
          {hosts.map((host) => (
            <HostShow key={host.id} host={host} webinarId={webinarId} />
          ))}
        </div>
      )}
      {!!!hosts?.length && <HostAdd webinarId={webinarId}></HostAdd>}
      {!!!hosts?.length && <HostInput webinarId={webinarId}></HostInput>}
    </div>
  );
};
export default AddHosts;
interface IDeleteParticularTypeSchedule {
  webinar: Webinar;
  type: WebinarScheduleType;
}
const DeleteParticularTypeSchedule: React.FC<IDeleteParticularTypeSchedule> = ({
  webinar,
  type,
}) => {
  const queryClient = useQueryClient();
  const deleteWebinarSchedulesForWebinarMutation = useMutation(
    ({ type }: { type: WebinarScheduleType }) => {
      let query = '';
      query += `?type=${type}`;
      return axiosInstance.delete(
        `/v1/webinars/${webinar.id}/webinarSchedules${query}`
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries([
          endpoints.webinarSchedules,
          'webinarId=',
          webinar.id,
          'type=',
          type,
        ]);
      },
    }
  );
  const webinarSchedulesQuery = useQuery(
    [endpoints.webinarSchedules, 'webinarId=', webinar.id, 'type=', type],
    () =>
      axiosInstance.get(
        `${endpoints.webinarSchedules}?webinarId=${webinar.id}&type=${type}`
      )
  );
  const schedules = webinarSchedulesQuery.data?.data || [];

  return (
    <ItemsContainer>
      <FilledButton
        variant="danger"
        onClick={() => {
          deleteWebinarSchedulesForWebinarMutation.mutate({
            type: type,
          });
        }}
      >
        Delete {type} Schedules ({schedules.length})
      </FilledButton>
      <ExpandableItem
        itemsName={`Webinar Schedules with type "${type}"`}
        data={schedules}
      />
      <MutationMessage
        mutation={deleteWebinarSchedulesForWebinarMutation}
      ></MutationMessage>
    </ItemsContainer>
  );
};

interface IEnrollBuldUsers { }
const EnrollBuldUsers: React.FC<IEnrollBuldUsers> = ({ }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const webinarScheduleIdInputRef = useRef<HTMLInputElement>(null);
  const refresh = useRefresh();
  const enrollBulkUsersMutation = useMutation((formData: FormData) => {
    const queryData = {
      webinarScheduleId: webinarScheduleIdInputRef.current.value,
      nameAccessor: 'First Name',
      emailAccessor: 'Email',
      phoneAccessor: 'Mobile',
    };
    const query = QueryString.stringify(queryData);
    return axiosInstance.post(
      `/v1/webinarSchedules/enroll-bulk-users?${query}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  });
  const handleUpload = useCallback(async () => {
    if (fileInputRef.current && fileInputRef.current.files?.[0]) {
      const formData = new FormData();
      // console.log({
      //   textFileToUpload: fileInputRef.current.files[0],
      // });
      formData.append('users-list', fileInputRef.current.files[0]);
      enrollBulkUsersMutation.mutate(formData);
    }
  }, [enrollBulkUsersMutation]);

  return (
    <div className="mt-8 space-y-4">
      <h2 className="text-2xl">Enroll Bulk Users for Webinar Schedule</h2>
      <label className="my-label">webinarScheduleId</label>
      <input
        className="my-input"
        type="text"
        placeholder="webinarScheduleId"
        ref={webinarScheduleIdInputRef}
      />
      <label className="my-label">{capitalize('users')} File:</label>
      <input
        className="my-input"
        ref={fileInputRef}
        type="file"
        onChange={refresh}
      />
      <FilledButton
        onClick={handleUpload}
        disabled={(fileInputRef.current?.files.length ?? 0) === 0}
      >
        {enrollBulkUsersMutation.status === 'idle'
          ? 'Upload'
          : enrollBulkUsersMutation.status === 'loading'
            ? 'Uploading'
            : enrollBulkUsersMutation.status === 'success'
              ? 'Uploaded'
              : 'Upload Failed'}
      </FilledButton>
      <MutationMessage mutation={enrollBulkUsersMutation} />
    </div>
  );
};
interface IHostShow {
  host: any;
  webinarId: number;
}
const HostShow: React.FC<IHostShow> = ({ host, webinarId }) => {
  const queryClient = useQueryClient();

  const deleteHostMutation = useMutation(
    () => {
      return axiosInstance.delete(`/v1/hosts/${host.id}`);
    },
    {
      onSuccess: (response) => {
        // console.log(response.data)
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
      },
    }
  );
  const { register, setValue, handleSubmit, reset } = useForm();
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const addHostMutation = useMutation(
    ({ hostId }: { hostId: number }) => {
      return axiosInstance.patch(`/v1/webinars/${webinarId}/hosts`, {
        hostId,
      });
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );

  return (
    <div className="mt-8 space-y-4">
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label htmlFor="hostId" className="my-label">
              Host
            </label>
            <input
              id="hostId"
              list="hosts"
              name="hostId"
              className="my-input"
              defaultValue={''}
              {...register('hostId', { required: true })}
            />
            <datalist id="hosts">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
          </div>
          <SaveButton type="submit">Change Host</SaveButton>
        </div>
      </form>
      <div>
        <label className="my-label">Name</label>
        <input disabled value={host.name} className="my-input" />
      </div>
      <div>
        <label className="my-label">title</label>
        <input disabled value={host.title} className="my-input" />
      </div>
      <div>
        <label className="my-label">Company</label>
        <input disabled value={host.company} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Bucket</label>
        <input disabled value={host.pictureUrlBucket} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Prefix</label>
        <input disabled value={host.pictureUrlPrefix} className="my-input" />
      </div>
      <div>
        <div>
          <span className="inline-block max-w-96 max-h-96 overflow-hidden">
            <img src={host.pictureUrl} alt="" />
          </span>
        </div>
      </div>
      <FilledButton
        onClick={() => {
          deleteHostMutation.mutate();
        }}
      >
        Delete
      </FilledButton>
    </div>
  );
};

interface IHostAdd {
  webinarId: number;
}

const HostAdd: React.FC<IHostAdd> = ({ webinarId }) => {
  const queryClient = useQueryClient();

  const { register, setValue, handleSubmit, reset } = useForm();
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const addHostMutation = useMutation(
    ({ hostId }: { hostId: number }) => {
      return axiosInstance.patch(`/v1/webinars/${webinarId}/hosts`, {
        hostId,
      });
    },
    {
      onSuccess: (response) => {
        // console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );

  return (
    <div className="mt-8 space-y-4">
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label htmlFor="hostId" className="my-label">
              Host
            </label>
            <input
              id="hostId"
              list="hosts"
              name="hostId"
              className="my-input"
              defaultValue={''}
              {...register('hostId', { required: true })}
            />
            <datalist id="hosts">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
          </div>
          <SaveButton type="submit">Add Host</SaveButton>
        </div>
      </form>
      {/* <div>
        <label className="my-label">Name</label>
        <input disabled value={host.name} className="my-input" />
      </div>
      <div>
        <label className="my-label">Designation</label>
        <input disabled value={host.designation} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Bucket</label>
        <input disabled value={host.pictureUrlBucket} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Prefix</label>
        <input disabled value={host.pictureUrlPrefix} className="my-input" />
      </div>
      <div>
        <div>
          <span className="inline-block max-w-96 max-h-96 overflow-hidden">
            <img src={host.pictureUrl} alt="" />
          </span>
        </div>
      </div> */}
    </div>
  );
};

interface IHostInput {
  webinarId: number;
}
const HostInput: React.FC<IHostInput> = ({ webinarId }) => {
  const { register, setValue, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();
  const addHostMutation = useMutation(
    ({
      name,
      pictureUrlBucket,
      pictureUrlPrefix,
      title,
      company,
      pictureUrl,
    }: {
      name: string;
      pictureUrlBucket: string;
      pictureUrlPrefix: string;
      title: string;
      company: string;
      pictureUrl: string;
    }) => {
      return axiosInstance.post(`/v1/webinars/${webinarId}/hosts`, {
        name,
        pictureUrlBucket,
        pictureUrlPrefix,
        title,
        company,
        pictureUrl,
      });
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );
  return (
    // Add new host completely
    <>
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Title</label>
            <input
              {...register('title', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Company</label>
            <input
              {...register('company', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Picture Bucket</label>
            <input
              {...register('pictureUrlBucket', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Prefix</label>
            <input
              {...register('pictureUrlPrefix', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Url</label>
            <input
              {...register('pictureUrl', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <FileUpload
                acceptedFileType="image"
                finalFileUrl={''}
                hostImageUpload={true}
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'pictureUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('pictureUrlPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
            </div>
          </div>
          <SaveButton type="submit">Add Host</SaveButton>
        </div>
      </form>
    </>
  );
};
/*
"https://player.vimeo.com/play/ea6e7b74-f393-4b5b-859c-6243b338d715/hls?s=704077672_1651401295_d5ce80ea108c2230f769500ad92123be&context=Vimeo%5CController%5CApi%5CResources%5CUser%5CVideoController.&log_user=0&oauth2_token_id=1604831868"
*/
