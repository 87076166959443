import axiosInstance from 'axiosInstance';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import {CSVLink} from 'react-csv'
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import FilledButton from 'components/FilledButton';
import { useSelect } from '@pankod/refine-core';

// Allowed extensions for input file
const allowedExtensions = ['csv'];

export const CreateBulkAudioBooks: React.FC = () => {
  const formRef = useRef(null);
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [runMutation, setRunMutation] = useState<boolean>();

  const { options } = useSelect({
    resource: 'authors',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  let availableAuthorsdata = []

  options.map((author)=>{availableAuthorsdata.push(author.value)})

  const [slotCreationStatus, setSlotCreationStatus] = useState('');

  // This state will store the parsed data
  //const [data, setData] = useState([]);

  let audioBookData = [];
  let filteredAudioBookData = [];

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState('');

  // It will store the file uploaded by the user
  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setError('');

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file');
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  let s3prefixes = [];
  const HandleParse = () => {
    // If user clicks the parse button without a file we show a error
    if (!file) return setError('Enter a valid file');

    // Initialize a reader which allows user to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file loads, we parse it and set the data.

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      parsedData.map((data) => {
        s3prefixes.push(data.s3Prefix);
        return s3prefixes;
      });

      let newAuthorsData = []
      for(let i=0; i<parsedData.length; i++){
        newAuthorsData.push(Number(parsedData[i].Author))
      }
      const authorsValidityCheck = newAuthorsData.filter(val => !availableAuthorsdata.includes(val));

      // addAuthorMutation

      for (let i = 0; i < parsedData.length; i++) {
        audioBookData.push(parsedData[i]);
        addAuthorMutation.mutate({
          name: parsedData[i].authorName,
          audioBookTitle: parsedData[i].Title,
        });
      }

      // if(authorsValidityCheck[0]){
      //   setSlotCreationStatus(`check Author Id's , Dont mess with the system" WrongId : ${authorsValidityCheck.toString()}`);
      // }else{

        for (let i = 0; i < parsedData.length; i++) {
          //audioBookData.push(parsedData[i]);
          uploadVideoFromS3ToVideoContentProvidersMutation.mutate({
            s3UrlBucket: 'videos.growthschool.io',
            s3UrlPrefix: parsedData[i].s3Prefix,
            audioBookTitle: parsedData[i].Title,
          });
        }

      //}
    };
    reader.readAsText(file);
  };

  const isAllDataProcessed = () => {
    for(let i=0; i< audioBookData.length;i++){
      if(!audioBookData[i].videoUuid || !audioBookData[i].duration || !audioBookData[i].videoDuration ){
        return false;
      }
    }
    return true;
  }

  const createScheduleMutation = useMutation(
    (audioData: any) => {
      setSlotCreationStatus("Uploading Audio Books")
      return axiosInstance.post('/v1/audio-books/bulk', {
        data: audioData,
      });
    },
    {
      onSuccess: (response) => {
        // queryClient.invalidateQueries([
        //   endpoints.webinarSchedules,
        //   'webinarId=',
        //   webinarId,
        // ]);
        //setData(audioData)
        setSlotCreationStatus('SuccessFully Created Audio Books');
      },
      onError: async () => {
        setSlotCreationStatus('Audio Books creation Failed Check Data once');
      },
    }
  );

  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
      audioBookTitle,
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
      audioBookTitle: string;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response, audioBookTitle, s3UrlPrefix) => {
        for (let i = 0; i < audioBookData.length; i++) {
          if (
            audioBookData[i].Title === audioBookTitle.audioBookTitle &&
            audioBookData[i].s3Prefix === audioBookTitle.s3UrlPrefix
          ) {
            audioBookData[i] = {
              ...audioBookData[i],
              videoUuid: response.data.video.uuid,
              duration: response.data.video.durationInMilliseconds,
              videoDuration: response.data.video.durationInMilliseconds,
            };
            if(isAllDataProcessed())  
            {
              createScheduleMutation.mutate(audioBookData);
            }
          }
        }
      },
    }
  );
  const addAuthorMutation = useMutation(
    ({
      name,
      audioBookTitle
    }: {
      name: string;
      audioBookTitle:string;
    }) => {
      return axiosInstance.post(`/v1/authors`, {
        name,
      });
    },
    {
      onSuccess: async (data , audioBookTitle) => {
        const author = data.data;
        for (let i = 0; i < audioBookData.length; i++) {
          if (
            audioBookData[i].Title === audioBookTitle.audioBookTitle &&
            audioBookData[i].authorName === audioBookTitle.name
          ) {
            audioBookData[i] = {
              ...audioBookData[i],
              authorId: data?.data?.id,
            };
          }
        }
        //navigation.push(`/authors`);
      },
      onError: async () => {
        window.location.reload();
      },
    }
  );

  // useEffect(() => {
  //   if (data[0]?.videoUuid) {
  //     createScheduleMutation.mutate(data);
  //   }
  // }, [data[0]?.videoUuid]);

  return (
    <OuterContainer>
      <div>
        <label htmlFor="csvInput" style={{ display: 'block' }}>
          Upload CSV File -
        </label>
        <br />
        <input
          onChange={handleFileChange}
          id="csvInput"
          name="file"
          type="File"
        />
        <br />
        <br />
        <div>
          <SaveButton onClick={HandleParse}>Create New Audio Books</SaveButton>
        </div>
        <br/>
        {/* <CSVLink filename = "webinarSlotDataCsv.csv" data={data}
            onClick={()=>{console.log("lastttt ----", data)}}
          ><FilledButton>Click Here to Download the CSV</FilledButton></CSVLink> */}
        <br />
        <h3 className="text-[25px]">{slotCreationStatus}</h3>
      </div>
    </OuterContainer>
  );
};
