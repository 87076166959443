export namespace Prisma {
  export type JsonValue = Record<string, any>;
}

/**
 * Model Subscription Tiers
 *
 */

export type SubscriptionTier = {
  id: number;
  tier: string;
  duration: number;
  price: number;
  createdAt: Date;
  updatedAt: Date;
  maxRetailPrice:number;
  subscriptionTypeId?:number;
};

export type Coupons = {
  subscriptionTierId?: number;
  id: number;
  couponCode: string;
  expiryDate: number;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
};


/**
 * Model Webinar
 *
 */
export type Webinar = {
  id: number;
  name: string;
  preHeader:string;
  duration: number;
  fbqPixelId: string | null;
  videoDuration: number;
  videoUuid: number;
  videoStart: number;
  videoEnd: number;
  createdAt: Date;
  updatedAt: Date;
  immediateSlotId: number | null;
  permanentSlots: Prisma.JsonValue;
  paletteId: number;
  webinarType: string;
  webinarThemeShape: string;
  thumbnailUrl:string;
  programId:number
};

export type TPartialMessage = {
  name: string;
  text: string;
  profileColor?: string | null | undefined;
  isHighlighted?:boolean;
  showInChat?:boolean
}

export type TWebinarSchedule = WebinarSchedule;

export type TPartialMessageWithReplies = TPartialMessage & {
  referenceDocumentId: string;
}

/**
 * Model WebinarCategory
 *
 */
export type WebinarCategory = {
  id: number;
  name: string;
};

/**
 * Model TopWorkshops
 *
 */
export type TopWorkshops = {
  id: number;
  webinar_id: number;
  webinar_category: number;
  sequence_number: number
};

/**
 * Model WebinarTopic
 *
 */
export type WebinarTopic = {
  id: number;
  name: string;
};

/**
 * Model WebinarBoosters
 *
 */
export type WebinarBoosters = {
  id: number;
  name: string;
};

/**
 * Model AudioBookTopic
 *
 */
export type AudioBookTopic = {
  id: number;
  name: string;
};

/**
 * Model AudioBookAuthor
 *
 */
export type AudioBookAuthor = {
  id: number;
  name: string;
};

/**
 * Model AudioBook
 *
 */
export type AudioBook = {
  guideCategories: any;
  transcript: string | number | readonly string[];
  isFeatured: string | number | readonly string[];
  affiliateLink: string | number | readonly string[];
  // authorName: string | number | readonly string[];
  // transcript: string | number | readonly string[];
  // learnings: string | number | readonly string[];
  authorId: any;
  id: number;
  title:string;           
  description:string;    
  duration:number;        
  videoDuration:number;  
  videoStart:number;   
  videoEnd:number;                
  thumbnailUrl:string;    
  thumbnailPrefix:string;   
  thumbnailUrlBucket:string;
  videoUuid:string;
  learnings:any;
  author:number;
  audioBookTopics:string[];
  newaudioBookTopics?: Array<any>;
};

/**
 * Model Guide
 *
 */

export type Guide = {
  map(arg0: (guide: any) => void): unknown;
  includes(args): unknown;
  id:number;
  title:string;           
  description:string;   
  topics:string[];
  categories:string[];
  guideCategories:string[];
  overview:string;   
  learnings:object;
  curators:number;  
  modules?: Array<any>;
  thumbnailUrl:string;  
  thumbnailPrefix:string;   
  thumbnailUrlBucket:string;
};

/**
 * Model Guide Resource
 *
 */

export type GuideResource = {
  id:number,
  moduleId:number,
  title:string; 
  resourceType:string,  
  resourceUrl:string,      
  guideId:number; 
  guideModule:number;
};

/**
 * Model Guide
 *
 */

export type GuideModule = {
  map(arg0: (guideModule: any) => void): unknown;
  includes(val: any): unknown;
  id:number;
  title:string;           
  guideId:number; 
  seqNumber:any;
};



/**
 * Model WebinarMetadata
 *
 */
export type WebinarMetadata = {
  id: number;
  json: Prisma.JsonValue;
  createdAt: Date;
  updatedAt: Date;
  webinarId: number;
};

/**
 * Model Survey
 *
 */
export type Survey = {
  id: number;
  webinarId: number;
  json: Prisma.JsonValue;
};

/**
 * Model Host
 *
 */
export type Host = {
  pictureUrl: string | number | readonly string[];
  title: string | number | readonly string[];
  company: string | number | readonly string[];
  id: number;
  name: string;
  pictureUrlBucket: string;
  pictureUrlPrefix: string;
  designation: string;
};

/**
 * Model ImmediateSlot
 *
 */
export type ImmediateSlot = {
  id: number;
  activeMethod: ImmediateSlotSelectionMethod;
  time: string;
  range: Prisma.JsonValue;
  values: Prisma.JsonValue;
};

/**
 * Model RecurringSchedule
 *
 */
export type RecurringSchedule = {
  timeOfDay: string;
  webinarId: number;
  scheduleExpiresAt: string;
  scheduleExpirationDelay: number;
};

/**
 * Model SurveyResponse
 *
 */
export type SurveyResponse = {
  id: number;
  webinarId: number | null;
  webinarScheduleId: number | null;
  webinarSignupId: number | null;
  surveyId: number | null;
  questionId: string;
  value: Prisma.JsonValue;
};

/**
 * Model WebinarSchedule
 *
 */
export type WebinarSchedule = {
  id: number;
  startTime: Date;
  expireAt: string;
  expirationDelay: number;
  type: WebinarScheduleType;
  webinarId: number;
};

/**
 * Model WebinarActivity
 *
 */
export type WebinarActivity = {
  id: number;
  type: WebinarActivityType;
  time: string;
  webinarSignupId: number;
  webinarId: number | null;
  webinarScheduleId: number | null;
  createdAt: Date;
};

/**
 * Model WebinarSignup
 *
 */
export type WebinarSignup = {
  id: number;
  name: string;
  email: string;
  phone: string | null;
  picture: string | null;
  userId: number | null;
  webinarId: number | null;
  webinarScheduleId: number | null;
  profileColor: string | null;
};

/**
 * Model Message
 *
 */
export type Message = {
  id: number;
  time: string;
  delay: number;
  name: string;
  text: string;
  webinarId: number;
  profileColor: string | null;
  showInChat: boolean;
  webinarSignupId: number | null;
};

/**
 * Model Poll
 *
 */
export type Poll = {
  id: number;
  time: string;
  delay: number;
  resultAt: string;
  resultDelay: number;
  totalVotes: number;
  question: string;
  webinarId: number;
};

/**
 * Model PollOption
 *
 */
export type PollOption = {
  id: number;
  text: string;
  share: number;
  pollId: number;
};

/**
 * Model DisableMessagingEvent
 *
 */
export type DisableMessagingEvent = {
  id: number;
  time: string;
  delay: number;
  expireAt: string;
  expirationDelay: number;
  webinarId: number;
};

/**
 * Model PinnedMessageEvent
 *
 */
export type PinnedMessageEvent = {
  id: number;
  name: string;
  text: string;
  profileColor: string | null;
  time: string;
  delay: number;
  expireAt: string;
  expirationDelay: number;
  webinarId: number;
};

/**
 * Model ResourceCta
 *
 */
export type ResourceCta = {
  id: number;
  time: string;
  delay: number;
  expireAt: string;
  expirationDelay: number;
  title: string;
  description: string | null;
  resourceLink: string;
  webinarId: number;
};

/**
 * Model OfferCta
 *
 */
export type OfferCta = {
  id: number;
  time: string;
  delay: number;
  expireAt: string;
  expirationDelay: number;
  programName: string;
  discountPercent: number;
  maxPrice: number;
  discountedPrice: number;
  redirectLink: string;
  webinarId: number;
};

/**
 * Model LivePeopleGraph
 *
 */
export type LivePeopleGraph = {
  id: number;
  webinarId: number;
  graphId: number;
};

/**
 * Model Graph
 *
 */
export type Graph = {
  id: number;
};

/**
 * Model Point
 *
 */
export type Point = {
  id: number;
  x: number;
  y: number;
  graphId: number;
};

/**
 * Enums
 */

// Based on
// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275

export const immediateSlotSelectionMethod = {
  particularTime: 'particularTime' as 'particularTime',
  randomBetweenRange: 'randomBetweenRange' as 'randomBetweenRange',
  ramdomFromValues: 'ramdomFromValues' as 'ramdomFromValues',
};

export type ImmediateSlotSelectionMethod =
  keyof typeof immediateSlotSelectionMethod;

export const webinarActivityType = {
  join: 'join' as 'join',
  leave: 'leave' as 'leave',
};

export type WebinarActivityType = keyof typeof webinarActivityType;

export const webinarScheduleType = {
  immediateSlot: 'immediateSlot',
  permanentSlot: 'permanentSlot',
  recurringSchedule: 'recurringSchedule',
  particularTime: 'particularTime',
  interval: 'interval',
  catalogueExclusive: 'catalogueExclusive',
  autoSchedule:'autoSchedule'
};

export type WebinarScheduleType = keyof typeof webinarScheduleType;

/**
 * Model User
 *
 */

export type User = {
  id: number;
  name: string;
  emails: string[];
  picture: string;
  phones: string[];
  isAdmin?: boolean;
};
