import GenericList from 'components/GenericList';
import ResourceCell from 'components/ResourceCell';
import endpoints from 'constants/endpoints';
import foreignKeys from 'constants/foreignKeys';

const webinarActivitiesResource = {
  //name: endpoints.webinarActivities,
  name: "webinarActivities",

  list: () => {
    return (
      <GenericList
        resource="webinarActivities"
        columns={[
          {
            id: 'id',
            Header: 'ID',
            accessor: 'id',
          },
          {
            id: 'type',
            Header: 'type',
            accessor: 'type',
          },
          {
            id: 'time',
            Header: 'time',
            accessor: 'time',
          },
          {
            id: 'createdAt',
            Header: 'createdAt',
            accessor: 'createdAt',
            Cell: ({ value }) => {
              return <p>{new Date(value).toLocaleString()}</p>;
            },
          },
          {
            id: foreignKeys.webinarSignupId,
            Header: 'webinar signup',
            accessor: foreignKeys.webinarSignupId,
            Cell: ({ cell }) => (
              <ResourceCell
                cell={cell}
                resource="webinarSignups"
                label="name"
                showId={true}
              />
            ),
          },
          {
            id: foreignKeys.webinarId,
            Header: 'webinar',
            accessor: foreignKeys.webinarId,
            Cell: ({ cell }) => (
              <ResourceCell
                cell={cell}
                resource="webinars"
                label="name"
                showId={true}
              />
            ),
          },

          {
            id: foreignKeys.webinarScheduleId,
            Header: 'webinar schedule',
            accessor: foreignKeys.webinarScheduleId,
            Cell: ({ cell }) => {
              return (
                <ResourceCell
                  cell={cell}
                  resource="webinarSchedules"
                  label="startTime"
                  labelModifier={(startTime) =>
                    new Date(startTime).toLocaleString()
                  }
                  showId={true}
                />
              );
            },
          },
        ]}
        filtersData={[
          {
            id: 'id',
            label: 'Id',
            type: 'number',
            inputWidth: 50,
          },
          {
            id: 'type',
            label: 'Type',
            type: 'dropdown',
            options: ['join', 'leave'],
          },
          {
            id: 'webinarSignupId',
            label: 'Webinar Signup Id',
            type: 'number',
          },
          {
            id: 'webinarId',
            label: 'Webinar Id',
            type: 'number',
          },
          {
            id: 'webinarScheduleId',
            label: 'Webinar Schedule Id',
            type: 'number',
          },
        ]}
      />
    );
  },
};
export default webinarActivitiesResource;
