import { useShow } from '@pankod/refine-core';

import ItemsContainer from 'components/ItemsContainer';
import OuterContainer from 'components/OuterContainer';

import ShowResource from './ShowResource';

type Common = {
  label: string;
  accessor: string;
};
export type TShowText = {
  type: 'text';
} & Common;
export type TShowTextArea = {
  type: 'textarea';
  rows?: number;
} & Common;
export type TShowResource = {
  type: 'resource';
  resource: string;

  extractProp: (data: any) => string;
} & Common;
export type TShowItem = TShowText | TShowTextArea | TShowResource;

interface IGenericShowProps {
  values: TShowItem[];
}
const GenericShow: React.FC<IGenericShowProps> = ({ values }) => {
  const { queryResult } = useShow<any>();
  const { data } = queryResult;
  const record = data?.data;

  return (
    <OuterContainer>
      <ItemsContainer>
        {values.map((value) => (
          <div key={value.accessor}>
            <label className="my-label">{value.label}</label>
            {(() => {
              switch (value.type) {
                case 'text':
                  return (
                    <input
                      value={record?.[value.accessor] || ''}
                      disabled
                      className="my-input"
                    />
                  );
                case 'textarea':
                  return (
                    <textarea
                      value={record?.[value.accessor] || ''}
                      disabled
                      rows={value.rows || 10}
                      className="my-input"
                    />
                  );
                case 'resource':
                  return (
                    <ShowResource
                      enabled={!!record}
                      resource={value.resource}
                      id={record?.[value.accessor]}
                      extractProp={value.extractProp}
                    />
                  );
              }
            })()}
          </div>
        ))}
      </ItemsContainer>
    </OuterContainer>
  );
};
export default GenericShow;
