import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import classNames from 'classnames';
import {
  Webinar,
  WebinarCategory,
  WebinarMetadata,
  WebinarTopic,
  WebinarBoosters
} from 'interfaces/apiTypes';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import CategoryPill from 'components/CategoryPill';
import FileUpload from 'components/FileUpload';
import { CrossIcon, LoadingIcon } from 'components/icons';
import InputErrorMessage from 'components/InputErrorMessage';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import WebinarExpandableItem from 'components/WebinarExpandableItem';
import webinarUtils from 'utils/webinarUtils';
import ThumbnailUpload from 'components/ThumbnailUpload';

// some comment
export const WebinarCreate: React.FC = () => {
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  // Options returns Hosts from the DB

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const AddNewWebinarTopic = useRef<HTMLInputElement>(null);
  const url = window.location.href.split('/');
  const webinarId = url?.[url.length - 1];
  const navigation = useNavigation();
  const formValues = watch();

  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedBoosters, setSelectedBoosters] = useState<string[]>([]);
  const [webinarType, setWebinarType] = useState<string>('');
  const [showAddNewTopicsInput, setshowAddNewTopicsInput] =
    useState<any>(false);
  const [selectedColorPaletteColor, setSelectedColorPaletteColor] =
    useState<string>();
  const [randomColorPaletteId, setRandomColorPaletteId] = useState<number>();
  const [existingMetaData, setexistingMetaData] = useState<any>();

  const [textInputValue, setTextInputValue] = useState(`
  {
    "title": "Build a Job-Ready UX Design Portfolio",
    "forWhom": [
        "Graphic designers who want to transition into the UX Design field",
        "Students who want to pursue a career in UX Design and just started learning",
        "UX Designers who are just beginning and are looking for growth in the field",
        "Product managers and Developers who want to gain UX Design knowledge",
        "Anyone appearing for Design Interviews in Product Companies"
    ],
    "learnings": [
        "Design Roles, Product Principles, and UX Problem Solving - My special tips and tricks.",
        "Deconstruct UX portfolios & find projects for your portfolio.",
        "Case Study:  Zomato’s Gold Subscription UX Breakdown.",
        "Ace UX design interview presentation like a pro."
    ],
    "registrationPageLink": "https://workshop.growthschool.io/workshop-ux-design-career-charu-2?id=8",
    "whatsappLink": ""
    }
  `);

  const webinarQuery = useQuery(
    ['webinars', webinarId],
    () =>
      axiosInstance.get<Webinar & { webinarCategories: WebinarCategory[] }>(
        `/v1/webinars/${webinarId}?_expand=webinarCategories&_expand=webinarTopics`
      ),
    {
      enabled: !!webinarId,
    }
  );
  const webinar = webinarQuery?.data?.data;

  const webinarMetadataQuery = useQuery(
    ['webinarMetadata', webinarId],
    () =>
      axiosInstance.get<
        WebinarMetadata & { WebinarMetadata: WebinarMetadata[] }
      >(`/v1/webinarMetadata/${webinarId}`),
    {
      enabled: !!webinarId,
    }
  );
  const webinarMetaData = webinarMetadataQuery?.data?.data;

  const webinarCategoriesQuery = useQuery(['webinarCategories'], () =>
    axiosInstance.get<WebinarCategory[]>(`/v1/webinarCategories`)
  );
  const webinarCategories = webinarCategoriesQuery?.data?.data;

  const webinarTopicsQuery = useQuery(['webinarTopics'], () =>
    axiosInstance.get<WebinarTopic[]>(`/v1/webinarTopics`)
  );
  const webinarTopics = webinarTopicsQuery?.data?.data;

  const webinarBoostersQuery = useQuery(['webinarBoosters'], () =>
    axiosInstance.get<WebinarBoosters[]>(`/v1/webinar-boosters`)
  );
  const webinarBoosters = webinarBoostersQuery?.data?.data;

  const existingCategories = useMemo(() => {
    return webinar?.webinarCategories.map((item) => item.name);
  }, [webinar?.webinarCategories]);
  const existingTopics = useMemo(() => {
    //@ts-ignore
    return webinar?.webinarTopics?.map((item: { name: any }) => item.name);
    //@ts-ignore
  }, [webinar?.webinarTopics]);

  useEffect(() => {
    if (webinar && webinar.webinarType) {
      setWebinarType(webinar.webinarType);
    }
  }, [webinar]);

  useEffect(() => {
    if (existingCategories) {
      setSelectedCategories(existingCategories);
    }
  }, [existingCategories]);

  useEffect(() => {
    if (existingTopics) {
      setSelectedTopics(existingTopics);
    }
  }, [existingTopics]);

  // For Setting Random Color Palette
  useEffect(() => {
    if (!webinar) {
      setRandomColorPaletteId(Math.floor(Math.random() * 12) + 1);
    } else {
      setRandomColorPaletteId(webinar.paletteId);
    }
  }, [webinar]);
  useEffect(() => {
    if (randomColorPaletteId) {
      // setSelectedColorPaletteId(Number(randomColorPaletteId));
      setSelectedColorPaletteColor(
        webinarUtils.getColorPalette(randomColorPaletteId)[0].cardColor
      );
    }
  }, [randomColorPaletteId]);

  // For Setting Existing MetaData if exists & Default MetaData if it Doesn't Exist
  useEffect(() => {
    if (webinarMetaData) {
      setexistingMetaData(webinarMetaData);
    } else {
      setexistingMetaData(textInputValue);
    }
  }, []);

  // All the Functions related to s3

  useEffect(() => {
    (async () => {
      if (formValues.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${formValues.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [formValues.videoUuid]);
  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
      isMuxRequired
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
      isMuxRequired: boolean;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
          isMuxRequired
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        setUploadedVideo(response.data);
      },
    }
  );
  const uploadThumbnailMutation = useMutation(
    ({
      thumbnailUrlBucket,
      thumbnailUrlPrefix,
      videoUuid,
    }: {
      thumbnailUrlBucket: string;
      thumbnailUrlPrefix: string;
      videoUuid: number;
    }) => {
      return axiosInstance.put(
        `${process.env.REACT_APP_CBC_SERVER}/videos/${videoUuid}/thumbnail`,
        {
          thumbnailUrlBucket,
          thumbnailUrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        setUploadedVideo(response.data);
      },
    }
  );

  useEffect(() => {
    if (uploadedVideo) {
      if (uploadedVideo?.streams?.preferred?.hls?.url) {
        setValue('videoUrl', uploadedVideo?.streams?.preferred?.hls?.url);
      }
      if (uploadedVideo.streams?.thumbnails?.png) {
        setValue('thumbnailUrl', uploadedVideo.streams.thumbnails.png);
      }
      setValue('s3UrlBucket', uploadedVideo.video.s3UrlBucket);
      setValue('s3UrlPrefix', uploadedVideo.video.s3UrlPrefix);
      setValue(
        'thumbnailUrlBucket',
        uploadedVideo.video.thumbnailUrlBucket ?? ''
      );
      setValue(
        'thumbnailUrlPrefix',
        uploadedVideo.video.thumbnailUrlPrefix ?? ''
      );
    }
  }, [setValue, uploadedVideo]);

  //create new Webinar Topics
  const [newWebinarTopics, setNewWebinarTopics] = useState<any>('');
  const createNewWebinarTopics = useMutation(
    () => {
      AddNewWebinarTopic.current.value = '';
      setNewWebinarTopics(newWebinarTopics.sort());
      setNewWebinarTopics(
        newWebinarTopics.filter(
          (item: any, index: any) => newWebinarTopics.indexOf(item) === index
        )
      );
      return axiosInstance.post(`/v1/webinarTopics`, {
        newWebinarTopics,
      });
    },
    {
      onSuccess: async (data) => {
        window.location.reload();
      },
    }
  );

  // create a new Webinar
  const createWebinarMutation = useMutation(
    () => {
      const { name, fbqPixelId, preHeader, hostId, thumbnailUrlBucket , thumbnailPrefix ,rating , ratingsCount , programId } = formValues;
      let webinarHostId = Number(hostId);
      let webinarMetaDataContent: any;
      if (webinarType === 'CBCWebinar') {
        webinarMetaDataContent = {};
      } else {
        webinarMetaDataContent = JSON.parse(textInputValue);
      }
      let thumbnailUrlLink = `https://${thumbnailUrlBucket}/${thumbnailPrefix}`
      return axiosInstance.post(`/v1/webinars`, {
        name,
        preHeader: preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        categories: selectedCategories,
        topics: selectedTopics,
        webinarType: webinarType,
        paletteId: randomColorPaletteId,
        hostId: webinarHostId,
        webinarMetadata: webinarMetaDataContent,
        thumbnailUrl:thumbnailUrlLink,
        rating,
        ratingsCount,
        booster:selectedBoosters,
        programId:Number(programId)
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`show/${webinar.id}`);
      },
      onError: async () => {
        // window.location.reload();
        // navigation.push(`/webinars/create`);
      },
    }
  );

  // update Webinar

  const updateWebinarMutation = useMutation(
    () => {
      const { name, fbqPixelId, preHeader , thumbnailUrlBucket , thumbnailPrefix ,rating , ratingsCount , programId } = formValues;
      // let webinarHostId = Number(hostId)s
      let webinarMetaDataContent: any;
      if (webinarType === 'CBCWebinar') {
        webinarMetaDataContent = {};
      } else {
        webinarMetaDataContent = JSON.parse(textInputValue);
      }
      let thumbnailUrlLink:string
      if(!thumbnailUrlBucket || !thumbnailPrefix){
        thumbnailUrlLink = webinar.thumbnailUrl
      }
      else{
        thumbnailUrlLink = `https://${thumbnailUrlBucket}/${thumbnailPrefix}`
      }
      
      return axiosInstance.patch(`/v1/webinars/${webinar.id}`, {
        name,
        preHeader: preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        categories: selectedCategories,
        topics: selectedTopics,
        webinarType: webinarType,
        paletteId: randomColorPaletteId,
        thumbnailUrl:thumbnailUrlLink,
        rating,
        ratingsCount,
        booster:selectedBoosters,
        programId:Number(programId)
        //hostId: webinarHostId,
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`/webinars`);
      },
    }
  );

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (webinar) {
            updateWebinarMutation.mutate();
          } else {
            createWebinarMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
          {/* Select webinar Topics */}

          <div>
            <div>
              <label htmlFor="TopicInput" className="my-label">
                Select Webinar Topics
              </label>

              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newTopic = e.target.value;
                  if (!selectedTopics.includes(newTopic)) {
                    setSelectedTopics([...selectedTopics, newTopic]);
                  }
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {webinarTopics?.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>

              <br></br>

              <div className="flex gap-x-2">
                {selectedTopics?.map((item) => {
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedTopics((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <button
                type="button"
                className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                onClick={() => {
                  setshowAddNewTopicsInput(!showAddNewTopicsInput);
                }}
              >
                Create New Topics
              </button>

              {showAddNewTopicsInput && (
                <div className="pt-[20px]">
                  <label htmlFor="preHeader" className="my-label">
                    Enter Topic
                  </label>
                  <input
                    ref={AddNewWebinarTopic}
                    type="text"
                    id="webinarTopics"
                    className="my-input"
                    placeholder="Growth Hacking"
                    autoComplete="off"
                    onChange={(e) => {
                      let newWebinarTopics = e.target.value;
                      if (newWebinarTopics.length <= 3) {
                        newWebinarTopics = newWebinarTopics.toUpperCase();
                        let finalWebinarTopics = [];
                        finalWebinarTopics = newWebinarTopics.split('?');
                        setNewWebinarTopics(finalWebinarTopics);
                      } else {
                        let newWebinarTopicsArray = newWebinarTopics.split(' ');
                        for (let i = 0; i < newWebinarTopicsArray.length; i++) {
                          newWebinarTopicsArray[i] =
                            newWebinarTopicsArray[i].charAt(0).toUpperCase() +
                            newWebinarTopicsArray[i].slice(1);
                        }
                        let finalWebinarTopics = [];
                        finalWebinarTopics = newWebinarTopicsArray
                          .toString()
                          .replaceAll(',', ' ')
                          .split('?');
                        setNewWebinarTopics(finalWebinarTopics);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                    onClick={() => {
                      createNewWebinarTopics.mutate();
                    }}
                  >
                    Add Topics
                  </button>
                </div>
              )}
            </div>

            <br />

            <label htmlFor="name" className="my-label">
              Webinar Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              className="my-input"
              placeholder="Name"
              autoComplete="off"
            />
            {errors.name && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>
          <div>
            <label htmlFor="preHeader" className="my-label">
              Webinar Preheader
            </label>
            <input
              {...register('preHeader', { required: true })}
              type="text"
              id="preHeader"
              className="my-input"
              placeholder="Enter Webinar Preheader"
              autoComplete="off"
            />
          </div>

          <div>
            <label htmlFor="categoryInput" className="my-label">
              Webinar Category
            </label>

            <div className="flex gap-x-2">
              {selectedCategories?.map((item) => {
                return (
                  <CategoryPill key={item}>
                    <p>{item}</p>
                    <div
                      onClick={() =>
                        setSelectedCategories((prev) => {
                          return prev.filter((i) => i !== item);
                        })
                      }
                      className="w-[16px] cursor-pointer"
                    >
                      {CrossIcon}
                    </div>
                  </CategoryPill>
                );
              })}
            </div>

            <select
              className="my-input mt-[12px]"
              value={''}
              defaultValue=""
              onChange={(e) => {
                const newCategory = e.target.value;
                if (!selectedCategories.includes(newCategory)) {
                  setSelectedCategories([...selectedCategories, newCategory]);
                }
              }}
            >
              <option value={''} disabled>
                Please select
              </option>
              {webinarCategories?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          
          <div>
              <label htmlFor="webinarType" className="my-label">
                Choose Webinar Type
              </label>
              <select
                required
                className="my-input mt-[12px]"
                placeholder="Choose Color"
                value={webinarType}
                onChange={(e) => setWebinarType(e.target.value)}
              >
                <option disabled value="">
                  Please select Webinar Type
                </option>
                <option value="CBCWebinar">CBC Webinar</option>
                <option value="AdWebinar">Ad Webinar</option>
                <option value="AppWebinar">App Webinar</option>
                <option value="FreeCourse">Free Course</option>
              </select>
            </div>

          {(!webinar && webinarType === 'AdWebinar') ||
          (!webinar && webinarType === 'AppWebinar') ? (
            <div>
              <label htmlFor="TopicInput" className="my-label">
                Add Webinar MetaData
              </label>
              <label className="text-[red]">
                Edit the below lines between "" for MetaData Changes{' '}
              </label>
              <textarea
                className={classNames({
                  'py-2 overflow-auto w-full h-full resize-none border-2 rounded-lg outline-none h-[600px]':
                    true,
                })}
                value={textInputValue}
                onChange={(e) => {
                  setTextInputValue(e.target.value);
                }}
              />
              <button
                type="button"
                className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                onClick={() => {
                  try {
                    JSON.parse(textInputValue);
                  } catch (err) {
                    alert(`Invalid MetaData ---- ${err}`);
                  }
                }}
              >
                Validate MetaData
              </button>
            </div>
          ) : (
            <></>
          )}

          {(webinar && webinarType === 'AdWebinar') ||
          (webinar && webinarType === 'AppWebinar') ? (
            <div>
              <label htmlFor="TopicInput" className="my-label">
                Add Webinar MetaData
              </label>
              <label className="text-[red]">
                Edit the below lines between "" for MetaData Changes{' '}
              </label>
              <WebinarExpandableItem
                itemsName="Metadata"
                dataKey="webinarMetadata"
                data={webinarMetaData?.json}
                rawData={webinarMetaData?.json}
                webinar={webinar}
              />
            </div>
          ) : (
            <></>
          )}

          <div>
            <div
              className={`w-[300px] h-[25px] ${selectedColorPaletteColor} p-[1px] m-[6px] rounded-[4px]`}
            >
              Webinar Theme Color
            </div>
            <input
              {...register('paletteId', {})}
              type="text"
              id="paletteId"
              className="my-input"
              disabled={true}
              value={randomColorPaletteId?.toString()}
              placeholder={randomColorPaletteId?.toString()}
            />

            <br/>

            <div>
            <label htmlFor="categoryInput" className="my-label">
              Select Webinar Booster
            </label>

            <div className="flex gap-x-2">
              {selectedBoosters?.map((item) => {
                return (
                  <CategoryPill key={item}>
                    <p>{item}</p>
                    <div
                      onClick={() =>
                        setSelectedBoosters((prev) => {
                          return prev.filter((i) => i !== item);
                        })
                      }
                      className="w-[16px] cursor-pointer"
                    >
                      {CrossIcon}
                    </div>
                  </CategoryPill>
                );
              })}
            </div>

            <select
              className="my-input mt-[12px]"
              value={''}
              defaultValue=""
              onChange={(e) => {
                const newBooster = e.target.value;
                if (!selectedBoosters.includes(newBooster)) {
                  setSelectedBoosters([...selectedBoosters, newBooster]);
                }
              }}
            >
              <option value={''} disabled>
                Please select
              </option>
              {webinarBoosters?.map((option) => (
                option.name!== "NEW" && <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
            </div>
            <br/>
            <div>
              <label htmlFor="webinarRating" className="my-label">
                Webinar Rating
              </label>
              <input
                {...register('rating', {})}
                type="text"
                id="rating"
                className="my-input"
                placeholder="4.0"
              />
            </div>
            <br/>
            <div>
              <label htmlFor="numberOfRatings" className="my-label">
                Number of Ratings
              </label>
              <input
                {...register('ratingsCount', {})}
                type="text"
                id="ratingsCount"
                className="my-input"
                placeholder="45"
              />
            </div>
            <br/>
            <div>
              <label htmlFor="programId" className="my-label">
                Program Id
              </label>
              <input
                {...register('programId', {})}
                type="text"
                id="programId"
                className="my-input"
                placeholder="20"
              />
            </div>

            {!webinar && (
              <div className="mt-8 space-y-4">
                <div>
                  <label htmlFor="hostId" className="my-label">
                    Host
                  </label>
                  <input
                    id="hostId"
                    list="hosts"
                    name="hostId"
                    className="my-input"
                    defaultValue={''}
                    {...register('hostId', { required: true })}
                  />
                  <datalist id="hosts">
                    <option value={''} disabled>
                      Please select
                    </option>
                    {options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            )}
          </div>

          <div className="hidden">
            <input {...register('videoUuid', { required: false })} />
          </div>
          <div>
            <label htmlFor="fbqPixelId" className="my-label">
              Facebook Tracking PixelId
            </label>
            <input
              {...register('fbqPixelId', {})}
              type="text"
              id="fbqPixelId"
              className="my-input"
              placeholder="2745785742367235"
            />
          </div>
          <div>
            <label htmlFor="s3UrlBucket" className="my-label">
              Video Bucket
            </label>
            <input
              {...register('s3UrlBucket', { required: true })}
              type="text"
              id="s3UrlBucket"
              className="my-input"
              placeholder="assets.growthschool.io"
            />
          </div>
          <div>
            <label htmlFor="s3UrlPrefix" className="my-label">
              Video Prefix
            </label>
            <input
              {...register('s3UrlPrefix', { required: true })}
              type="text"
              id="s3UrlPrefix"
              className="my-input"
              placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            />
          </div>

          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <FileUpload
                acceptedFileType="video"
                finalFileUrl={formValues.videoUrl}
                setFinalFileUrl={async (presignedPost) => {
                  if (presignedPost) {
                    setValue('s3UrlBucket', presignedPost.fields['bucket']);
                    setValue('s3UrlPrefix', presignedPost.fields['Key']);
                  }
                }}
              />
            </div>
            {errors.videoUrl && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>
          <div>
            <button
              type="button"
              className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
              onClick={() => {
                const { s3UrlBucket, s3UrlPrefix } = formValues;
                let isMuxRequired = false
                // isMuxRequired is set to false since we are not using MUX right if there is any need in future to add mux back just start using this isMuxRequired flag
                uploadVideoFromS3ToVideoContentProvidersMutation.mutate({
                  s3UrlBucket: s3UrlBucket,
                  s3UrlPrefix: s3UrlPrefix,
                  isMuxRequired:isMuxRequired
                });
              }}
            >
              Confirm Video
            </button>
            <div>
              <MutationMessage
                mutation={uploadVideoFromS3ToVideoContentProvidersMutation}
              />
            </div>
          </div>

          {webinar && 
          <div>
            <h1>Current Thumbnail</h1><br/>
            <img src={webinar.thumbnailUrl} alt='webinarThumbnail' width={300}/><br/>
            <p>NOTE: Upload New Thumbnail below if you want to replace the existing thumbnail else hit the save button directly</p>
          </div>
          }
          <div>
            <label htmlFor="thumbnailUrlBucket" className="my-label">
              Thumbnail Bucket
            </label>
            <input
              {...register('thumbnailUrlBucket',)}
              type="text"
              id="thumbnailUrlBucket"
              className="my-input"
              placeholder="assets.growthschool.io"
            />
          </div>
          <div>
            <label htmlFor="thumbnailPrefix" className="my-label">
              Thumbnail Key
            </label>
            <input
              {...register('thumbnailPrefix')}
              type="text"
              id="thumbnailPrefix"
              className="my-input"
              placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            />
          </div>
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
            <ThumbnailUpload
                acceptedFileType="image"
                finalFileUrl={''}
                thumbnailType='webinar-thumbnail'
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'thumbnailUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('thumbnailPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
            </div>
            {errors.thumbnailUrl && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>
          <div>
            <button
              type="button"
              className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
              onClick={() => {
                const { thumbnailUrlBucket, thumbnailPrefix } = formValues;
                if (uploadedVideo?.video.id) {
                  uploadThumbnailMutation.mutate({
                    thumbnailUrlBucket: thumbnailUrlBucket,
                    thumbnailUrlPrefix: thumbnailPrefix,
                    videoUuid: uploadedVideo.video.uuid,
                  });
                }
              }}
            >
              Confirm Thumbnail
            </button>
            <div>
              <MutationMessage mutation={uploadThumbnailMutation} />
            </div>
          </div>

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createWebinarMutation} />
      <MutationMessage mutation={updateWebinarMutation} />
    </OuterContainer>
  );
};
