import { useOne } from '@pankod/refine-core';

import { WebinarSchedule } from 'interfaces/apiTypes';
import { Link } from 'react-router-dom';

interface IResourceCellProps {
  cell: any;
  resource: string;
  label: string;
  labelModifier?: (label: string) => string;
  customLabel?: (v: any) => string;
  showId?: boolean;
}
const ResourceCell: React.FC<IResourceCellProps> = ({
  cell,
  resource,
  label,
  labelModifier = (v) => v,
  customLabel,
  showId = true,
}) => {
  const { data, isLoading } = useOne<WebinarSchedule>({
    resource: resource,
    id: cell.value,
  });
  if (isLoading) {
    return <p>loading..</p>;
  }
  if (!data) {
    return <>Failed to load</>;
  }
  return (
    <Link to={`/${resource}/show/${cell.value}`}>
      {showId && 'id: ' + data.data.id + ', ' + label + ' : '}
      {customLabel
        ? customLabel(data.data)
        : labelModifier(data?.data?.[label])}
    </Link>
  );
};
export default ResourceCell;
