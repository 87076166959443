import { useForm } from '@pankod/refine-react-hook-form';

import { TCreateField } from 'interfaces/web';

import { LoadingIcon } from './icons';
import InputErrorMessage from './InputErrorMessage';
import ItemsContainer from './ItemsContainer';
import OuterContainer from './OuterContainer';
import SaveButton from './SaveButton';
import SelectResource from './SelectResource';

interface IGenericCreateOrEditProps {
  fields: TCreateField[];
}
const GenericCreateOrEdit: React.FC<IGenericCreateOrEditProps> = ({
  fields,
}) => {
  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  return (
    <OuterContainer>
      <form onSubmit={handleSubmit(onFinish)}>
        <ItemsContainer>
          {fields.map((field) => {
            return (
              <div key={field.name}>
                {(() => {
                  switch (field.type) {
                    case 'text': {
                      return (
                        <>
                          <label htmlFor={field.name} className="my-label">
                            {field.label || field.name}
                          </label>
                          <input
                            id={field.name}
                            type="text"
                            className="my-input"
                            placeholder={field.placeholder || field.name}
                            {...register(field.name, { required: true })}
                          />
                        </>
                      );
                    }
                    case 'select': {
                      return (
                        <SelectResource
                          resource={field.resource}
                          optionLabel={field.optionLabel}
                          selectProps={register(field.name, { required: true })}
                          labelModifier={field.labelModifier}
                          defaultValue={queryResult.data?.data?.[field.name]}
                          optionsEffect={() => {
                            resetField(field.name);
                          }}
                        />
                      );
                    }
                    case 'textarea': {
                      return (
                        <>
                          <label htmlFor={field.name} className="my-label">
                            {field.label || field.name}
                          </label>
                          <textarea
                            id={field.name}
                            className="my-input"
                            placeholder={field.placeholder || field.name}
                            rows={field.rows}
                            {...register(field.name, { required: true })}
                          />
                        </>
                      );
                    }
                  }
                })()}
                {errors[field.name] && (
                  <InputErrorMessage>
                    <span className="font-medium">Oops!</span> This field is
                    required
                  </InputErrorMessage>
                )}
              </div>
            );
          })}

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
    </OuterContainer>
  );
};
export default GenericCreateOrEdit;
