import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { SubscriptionTier } from 'interfaces/apiTypes';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

// some comment
export const Payments: React.FC = () => {
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const navigation = useNavigation();
  const formValues = watch();
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [webinarType, setWebinarType] = useState<string>('');

  const [tierType, setTierType] = useState<string>('');

  const url = window.location.href.split('/');
  const subscriptionTierId = url?.[url.length - 1];

  const paymentsQuery = useQuery(
    ['subscriptionTiers', subscriptionTierId],
    () =>
      axiosInstance.get<SubscriptionTier>(
        `/v1/subscriptionTiers/${subscriptionTierId}`
      ),
    {
      enabled: !!subscriptionTierId,
    }
  );
  const subscriptionTier = paymentsQuery?.data?.data;

  // create a new Subscription Tier
  const createSubscriptionTierMutation = useMutation(
    () => {
      const { price, maxRetailPrice } = formValues;
      return axiosInstance.post(`/v1/subscriptionTiers`, {
        tier: tierType,
        price,
        maxRetailPrice,
      });
    },
    {
      onSuccess: async () => {
        navigation.push(`/subscriptionTiers`);
      },
    }
  );

  // update Subscription Tier
  const updateSubscriptionTierMutation = useMutation(
    () => {
      const { price, maxRetailPrice } = formValues;
      return axiosInstance.patch(
        `/v1/subscriptionTiers/${subscriptionTier.id}`,
        {
          price,
          maxRetailPrice,
        }
      );
    },
    {
      onSuccess: async () => {
        navigation.push(`/subscriptionTiers`);
      },
    }
  );

  // const updateWebinarMutation = useMutation(
  //   () => {
  //     const { name, fbqPixelId , preHeader , hostId } = formValues;
  //     let webinarHostId = Number(hostId)
  //     let webinarMetaDataContent:any;
  //     if(webinarType === "CBCWebinar"){
  //       webinarMetaDataContent = {}
  //     }
  //     else{
  //       webinarMetaDataContent = JSON.parse(textInputValue)
  //     }
  //     return axiosInstance.patch(`/webinars/${webinar.id}`, {
  //       name,
  //       preHeader:preHeader,
  //       fbqPixelId: fbqPixelId || undefined,
  //       videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
  //       videoUuid: uploadedVideo.video.uuid,
  //       categories: selectedCategories,
  //       topics: selectedTopics,
  //       webinarType: webinarType,
  //       paletteId: randomColorPaletteId,
  //       hostId:webinarHostId,
  //       webinarMetadata: webinarMetaDataContent,
  //     });
  //   },
  //   {
  //     onSuccess: async (data) => {
  //       const webinar = data.data;
  //       navigation.push(`/webinars/show/${webinar.id}`);
  //     },
  //   }
  // );

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (!subscriptionTier) {
            createSubscriptionTierMutation.mutate();
          } else {
            updateSubscriptionTierMutation.mutate();
          }
          //createSubscriptionTierMutation.mutate();
        })}
      >
        <ItemsContainer>
          {/* Select webinar Topics */}
          <div>
            <label htmlFor="webinarTier" className="my-label">
              Choose Tier
            </label>
            {subscriptionTier && (
              <p className="text-[blue]">
                Current Tier is - {subscriptionTier.tier}
              </p>
            )}
            <select
              required
              className="my-input mt-[12px]"
              placeholder=""
              onChange={(e) => {
                setTierType(e.target.value);
              }}
            >
              <option selected disabled>
                Slect Tier
              </option>
              <option value="Tier1">Tier 1</option>
              <option value="Tier2">Tier 2</option>
              <option value="Tier3">Tier 3</option>
              <option value="Tier4">Tier 4</option>
            </select>
          </div>

          <div>
            <label htmlFor="price" className="my-label">
              Price
            </label>
            <input
              {...register('price', { required: true })}
              type="number"
              id="price"
              className="my-input"
              placeholder="Enter Price"
              autoComplete="off"
            />
          </div>

          <div>
            <label htmlFor="maxRetailPrice" className="my-label">
              max Retail Price
            </label>
            <input
              {...register('maxRetailPrice', { required: true })}
              type="number"
              id="price"
              className="my-input"
              placeholder="Enter Price"
              autoComplete="off"
            />
          </div>

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createSubscriptionTierMutation} />
      <MutationMessage mutation={updateSubscriptionTierMutation} />
    </OuterContainer>
  );
};
